import { fetcher } from '../fetcher';

export const examsService = {
  get: async () => {
    return await fetcher(`/exams`, {
      method: 'GET',
      schema: examsSchema,
    });
  },
};
